import React from 'react'
import Portfolios from '../components/portfolios'
import PortfoliosJSON from "../content/portfolios.json"
import EnvironmentalChart from "../components/environmental-chart"

export default function environmental() {
    const content = PortfoliosJSON.portfolios[4]
    return (
        <Portfolios content={content} subcontent={""} chart={<EnvironmentalChart/>} />
    )
}
